import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Typography, Empty } from 'antd';
import UserDefinedEdit from './ActivityOrdersTable/UserDefinedEdit';

const { Title } = Typography;

const ActivityOrderDescription = ({ data, callback = () => {} }) => {
    if (!data || Object.keys(data).length === 0) {
        return <Empty description="No order data available" />;
    }

    const { id = 'N/A', createdAt, userDefined = 'N/A', billingTotal, serviceFee, commissionAmount } = data;

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
    };

    const formatCurrency = (amount) => {
        if (amount === undefined || amount === null) return 'N/A';
        return `AUD ${Number(amount).toFixed(2)}`;
    };

    return (
        <div>
            <Title level={4}>活动订单详情</Title>
            <Descriptions column={2}>
                <Descriptions.Item label="User Defined">
                    <UserDefinedEdit userDefined={userDefined} orderID={id} callback={callback} />
                </Descriptions.Item>
                <Descriptions.Item label="Created At">{formatDate(createdAt)}</Descriptions.Item>
                <Descriptions.Item label="Billing Total">{formatCurrency(billingTotal)}</Descriptions.Item>
                <Descriptions.Item label="Service Fee">{formatCurrency(serviceFee)}</Descriptions.Item>
                {/* <Descriptions.Item label="Commission Amount">{formatCurrency(commissionAmount)}</Descriptions.Item> */}
            </Descriptions>
        </div>
    );
};

ActivityOrderDescription.propTypes = {
    data: PropTypes.object,
    callback: PropTypes.func,
};

export default ActivityOrderDescription;
