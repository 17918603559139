import { useState, useRef, useCallback } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useActivityOrder() {
    const [data, setData] = useState({});
    const [order, setOrder] = useState({});
    const [fetching, setFetching] = useState(false);
    const abortControllerRef = useRef(null);

    const fetchActivityOrder = useCallback(async (orderId) => {
        abortControllerRef.current = new AbortController();
        setFetching(true);

        try {
            const response = await fetch(`${config.apiURL}/order/${orderId}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An error occurred while fetching the activity order');
            }

            const responseData = await response.json();
            setData(responseData);
            const { order } = responseData;
            setOrder(order);
            return responseData;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching activity order:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, order, data, fetchActivityOrder, abort };
}

export default useActivityOrder;
