import { useCallback, useState, useRef, useEffect } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import PropTypes from 'prop-types';

function useContacts(props) {
    let relationID = '';
    if (props) {
        relationID = props.relationID;
    }
    // Fetching
    const [fetching, setFetching] = useState(false);
    const [contacts, setContacts] = useState([]);
    const abortFetchController = useRef(null);
    // Adding
    const [adding, setAdding] = useState(false);
    // Deteting
    const [deleting, setDeleting] = useState(false);

    const fetchContacts = useCallback(async (ownerID) => {
        setFetching(true);
        abortFetchController.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/contacts?ownerID=${ownerID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
                signal: abortFetchController.current.signal,
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch contacts');
            }
            const responseData = await response.json();
            const { contacts } = responseData;
            if (contacts) {
                setContacts(contacts);
            }
            return contacts;
        } catch (error) {
            console.error('Error fetching contacts:', error);
        } finally {
            setFetching(false);
        }
    }, []);

    useEffect(() => {
        if (relationID) {
            fetchContacts(relationID);
        }
    }, [relationID, fetchContacts]);

    const addContact = useCallback(async (contactData) => {
        setAdding(true);
        try {
            const response = await fetch(`${config.apiURL}/contacts`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to add contact');
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error adding contact:', error);
        } finally {
            setAdding(false);
        }
    }, []);

    const deleteContact = useCallback(async (contactID) => {
        setDeleting(true);
        try {
            const response = await fetch(`${config.apiURL}/contacts/${contactID}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete contact');
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error deleting contact:', error);
        } finally {
            setDeleting(false);
        }
    }, []);

    const abortFetch = useCallback(() => {
        if (abortFetchController.current) {
            abortFetchController.current.abort();
        }
    }, []);

    return { fetching, contacts, fetchContacts, abortFetch, adding, addContact, deleting, deleteContact };
}

useContacts.propTypes = {
    relationID: PropTypes.string,
};

export default useContacts;
