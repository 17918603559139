import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router';
import OrderPage from './OrderPage';
import OrderItemPage from './OrderItemPage';

function Index() {
    const { orderID } = useParams();

    useEffect(() => {
        // console.log('orderID:', orderID);
    }, [orderID]);

    return (
        <Routes>
            <Route path="" exact element={<OrderPage />} />
            <Route path="/" exact element={<OrderPage />} />
            <Route
                path="/item/:orderItemID/*"
                exact
                element={
                    <Routes>
                        <Route path="*" exact element={<OrderItemPage />} />
                    </Routes>
                }
            />
        </Routes>
    );
}

export default Index;
