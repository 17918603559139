import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, App, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import useContacts from '../../hooks/useContacts';

const labelCol = {
    span: 2,
    offset: 0,
};

const wrapperCol = {
    span: 12,
    offset: 0,
};

function Contacts({ relationID, callback = () => {} }) {
    const { fetching, contacts, fetchContacts, abortFetch, addContact, adding, deleteContact } = useContacts();
    const { message } = App.useApp();
    const [form] = Form.useForm();

    useEffect(() => {
        if (relationID) {
            fetchData();
        }
        return () => {
            abortFetch();
        };
    }, [relationID]);

    const fetchData = () => {
        fetchContacts(relationID).catch((error) => {
            console.error('Error fetching contacts:', error);
            message?.error(error.message);
        });
    };

    const onFinish = (values) => {
        // console.log('Received values:', values);
        addContact({ ...values, ownerID: relationID, sort: contacts.length + 1 })
            .then((data) => {
                form.resetFields();
                fetchData();
                const { reply } = data;
                if (reply) {
                    message?.success(reply);
                }
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding contact:', error);
                message?.error(error.message);
            });
    };

    const handleDelete = (id) => {
        deleteContact(id)
            .then((data) => {
                fetchData();
                const { reply } = data;
                if (reply) {
                    message?.success(reply);
                }
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error deleting contact:', error);
                message?.error(error.message);
            });
    };

    return (
        <>
            <Form form={form} layout="horizontal" onFinish={onFinish} labelWrap autoComplete="off">
                <Form.Item labelCol={labelCol} label="客人信息" required>
                    <div className={styles.contact_container}>
                        <Form.Item
                            required
                            name={'firstname'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Input placeholder="First Name" style={{ width: 150 }} />
                        </Form.Item>
                        <Form.Item
                            required
                            name={'lastname'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Input placeholder="Last Name" style={{ width: 150 }} />
                        </Form.Item>
                    </div>
                    <Button type="dashed primary" htmlType="submit" loading={adding}>
                        <PlusOutlined /> 添加客人
                    </Button>
                </Form.Item>
                {contacts.length > 0 && (
                    <Form.Item labelCol={labelCol} label={'已添加'}>
                        <Space direction="vertical">
                            {contacts.map((contact, index) => {
                                return (
                                    <div key={index} className={styles.existing_contact_container}>
                                        <div>
                                            {contact.lastname}/{contact.firstname}
                                        </div>
                                        <Button
                                            type="link"
                                            size="small"
                                            danger
                                            onClick={() => handleDelete(contact.id)}
                                        >
                                            移除
                                        </Button>
                                    </div>
                                );
                            })}
                        </Space>
                    </Form.Item>
                )}
            </Form>
        </>
    );
}

Contacts.propTypes = {
    relationID: PropTypes.string,
    callback: PropTypes.func,
};

export default Contacts;
