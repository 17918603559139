import { useState, useCallback } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useOrderItem() {
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [orderItem, setOrderItem] = useState({});
    // verifying
    const [verifying, setVerifying] = useState(false);
    // verifyData
    const [verifyData, setVerifyData] = useState({
        readyToBook: false,
        readyToConfirm: false,
    });
    // updating booking info
    const [updating, setUpdating] = useState(false);
    // adding
    const [adding, setAdding] = useState(false);
    // deleting
    const [deleting, setDeleting] = useState(false);
    // confirming
    const [confirming, setConfirming] = useState(false);

    const fetchOrderItem = useCallback(async (itemId) => {
        setFetching(true);
        setFetched(false);
        try {
            const response = await fetch(`${config.apiURL}/order-items/${itemId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch order item');
            }

            const responseData = await response.json();
            const { orderItem } = responseData;
            if (orderItem) {
                setOrderItem(orderItem);
            }
            return orderItem;
        } catch (error) {
            console.error('Error fetching order item:', error);
            throw error;
        } finally {
            setFetching(false);
            setFetched(true);
        }
    }, []);

    const verifyOrderItem = useCallback(async (itemId) => {
        setVerifying(true);
        try {
            const response = await fetch(`${config.apiURL}/order-items/${itemId}/verify`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ envolopOnly: true }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to verify order item');
            }
            const responseData = await response.json();
            setVerifyData(responseData);
            return responseData;
        } catch (error) {
            console.error('Error verify order item:', error);
            throw error;
        } finally {
            setVerifying(false);
        }
    }, []);

    const addOrderItem = useCallback(async (orderItemData) => {
        setAdding(true);
        try {
            const response = await fetch(`${config.apiURL}/order-items`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderItemData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create order item');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error creating order item:', error);
            throw error;
        } finally {
            setAdding(false);
        }
    }, []);

    const updateOrderItem = useCallback(async (itemId, updateData) => {
        setUpdating(true);
        try {
            const response = await fetch(`${config.apiURL}/order-items/${itemId}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update order item');
            }

            const updatedItem = await response.json();
            setOrderItem(updatedItem);
            return updatedItem;
        } catch (error) {
            console.error('Error updating order item:', error);
            throw error;
        } finally {
            setUpdating(false);
        }
    }, []);

    const deleteOrderItem = useCallback(async (itemId) => {
        setDeleting(true);
        try {
            const response = await fetch(`${config.apiURL}/order-items/${itemId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete order item');
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error deleting order item:', error);
            throw error;
        } finally {
            setDeleting(false);
        }
    }, []);

    const confirmOrderItem = useCallback(async (itemId) => {
        setConfirming(true);
        try {
            const response = await fetch(`${config.apiURL}/order-items/${itemId}/confirm`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderItemID: itemId,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to confirm order item');
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error confirming order item:', error);
            throw error;
        } finally {
            setConfirming(false);
        }
    }, []);

    return {
        orderItem,
        verifyData,
        verifying,
        fetching,
        fetched,
        adding,
        updating,
        deleting,
        confirming,
        fetchOrderItem,
        addOrderItem,
        updateOrderItem,
        deleteOrderItem,
        verifyOrderItem,
        confirmOrderItem,
    };
}

export default useOrderItem;
